<template>
    <auth-layout>
        <auth-form back-route="auth-login" :action="action" :callback="submit">
            <template #header>
                <h1>{{ $t('auth_new_password.title') }}</h1>
                <p>{{ $t('auth_new_password.subtitle') }}</p>
            </template>

            <validation-provider name="email" rules="required|email" v-slot="{ errors, reset }" slim>
                <input-group :label="$t('auth_new_password.email.label')" :errors="errors">
                    <input-component
                        type="email"
                        v-model="email"
                        :placeholder="$t('auth_new_password.email.placeholder')"
                        @input="reset"
                        @mousedown.stop
                    />
                </input-group>
            </validation-provider>

            <template #button>
                {{ $t('auth_new_password.submit_button') }}
                <arrow-right-icon />
            </template>
        </auth-form>
    </auth-layout>
</template>

<script>
    import { DateTime } from 'luxon';
    import { ValidationProvider } from 'vee-validate';
    import AuthForm from '@/components/auth/AuthForm';
    import ArrowRightIcon from '@/components/icons/ArrowRightIcon';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';
    import Toast from '@/components/Toast';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import { requestResetPassword } from '@/services/skinsPaymentApi';
    import { NEW_PASSWORD_CODE_TIMEOUT } from '@/services/skinsPaymentApi/constants/common';

    export default {
        name: 'NewPassword',
        components: {
            ValidationProvider,
            AuthForm,
            ArrowRightIcon,
            InputComponent,
            InputGroup,
            AuthLayout,
        },
        computed: {
            action() {
                return 'user-cabinet-api/auth/password-forgot';
            },
        },
        methods: {
            submit(recaptchaToken) {
                return requestResetPassword(this.email, recaptchaToken).then(() => {
                    sessionStorage.setItem('AUTH_NEW_PASSWORD_EMAIL', this.email);
                    sessionStorage.setItem(
                        'AUTH_NEW_PASSWORD_TIMER_END',
                        DateTime.now().plus({ seconds: NEW_PASSWORD_CODE_TIMEOUT }).toUnixInteger()
                    );

                    this.$toast({
                        component: Toast,
                        props: {
                            type: 'success',
                            text: this.$t('auth_new_password.toasts.request_reset_password_success'),
                        },
                    });

                    this.$router.push({ name: 'auth-new-password-code' });
                });
            },
        },
        data() {
            return {
                email: '',
            };
        },
    };
</script>
