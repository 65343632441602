<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.8869 8.64966C21.5837 7.86089 22.7965 7.77891 23.5958 8.46656L35.3417 18.5718C35.7599 18.9316 36 19.4524 36 20C36 20.5476 35.7599 21.0684 35.3417 21.4282L23.5958 31.5334C22.7965 32.2211 21.5837 32.1391 20.8869 31.3503C20.1901 30.5616 20.2731 29.3647 21.0724 28.677L28.9559 21.8947L5.92 21.8947C4.85961 21.8947 4 21.0464 4 20C4 18.9536 4.85961 18.1053 5.92 18.1053L28.9559 18.1053L21.0724 11.323C20.2731 10.6353 20.1901 9.43844 20.8869 8.64966Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ArrowRightIcon',
    };
</script>
